<template>
  <div>
    <div v-if="groupedFiles.images.length" class="grid">
      <PostFile
        v-for="(file, index) in groupedFiles.images"
        :key="file.url"
        :file="file"
        class="col-12 sm:col-4 md:col-2 mb-3"
        display-name
        @show-file="setDisplayFiles(groupedFiles.images, index)"
      />
    </div>

    <div v-if="groupedFiles.videos.length" class="grid">
      <PostFile
        v-for="(file, index) in groupedFiles.videos"
        :key="file.url"
        :file="file"
        class="col-12 sm:col-6 mb-3"
        display-name
        @show-file="setDisplayFiles(groupedFiles.videos, index)"
      />
    </div>

    <div v-if="groupedFiles.others.length" class="flex">
      <PostFile
        v-for="(file, index) in groupedFiles.others"
        :key="file.url"
        :file="file"
        class=""
        display-name
        @show-file="setDisplayFiles(groupedFiles.others, index)"
      />
    </div>
  </div>
</template>

<script>
import PostFile from "./PostFile.vue";

export default {
  components: { PostFile },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    groupedFiles() {
      const images = [];
      const videos = [];
      const others = [];

      this.files.forEach((file) => {
        if (this.$root.fileIsImage(file)) {
          images.push(file);
        } else if (
          this.$root.fileIsVideo(file) ||
          this.$root.fileIsMkvVideo(file)
        ) {
          videos.push(file);
        } else {
          others.push(file);
        }
      });

      return { images, videos, others };
    },
  },
  methods: {
    setDisplayFiles(files, index) {
      this.$emit("show-file", files, index);
    },
  },
};
</script>
