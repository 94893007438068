<template>
  <div>
    <Sidebar
      v-model:visible="isVisible"
      :show-close-icon="!isLoading"
      :dismissable="false"
      class="p-sidebar-md mb-6"
      position="right"
      style="min-width: 50vw"
    >
      <h1>{{ $t("edit_profile") }}</h1>
      <ProgressBar v-if="isLoading" mode="indeterminate" />

      <div v-else class="p-fluid formgrid grid">
        <div class="col-12 sm:col-6 sm:pr-3 md:col-7 md:pr-5">
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("first_names") }}
            </h4>
            <div class="col-12 md:col-7">
              <InputText v-model="formObject.firstNames" type="text" />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("last_names") }}
            </h4>
            <div class="col-12 md:col-7">
              <InputText v-model="formObject.lastNames" type="text" />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("birth_date") }}
            </h4>
            <div class="col-12 md:col-7">
              <Calendar
                v-model="formObject.birthDate"
                :show-icon="true"
                placeholder="31/12/1950"
                date-format="dd/mm/yy"
              />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("marital_status") }}
            </h4>
            <div class="col-12 md:col-7">
              <Dropdown
                v-model="formObject.maritalStatus"
                :options="$root.options.maritalStatus"
                :placeholder="$t('dropdown_select')"
                option-label="label"
                option-value="value"
              />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("profession") }}
            </h4>
            <div class="col-12 md:col-7">
              <Dropdown
                v-model="formObject.profession"
                :options="professions"
                option-label="name"
                option-value="name"
                :placeholder="$t('type_or_select')"
                scroll-height="400px"
                filter
                show-clear
              />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("naturalness") }}
            </h4>
            <div class="col-12 md:col-7">
              <InputText v-model="formObject.naturalness" type="text" />
            </div>
          </div>
          <div class="bl-info mb-3 grid mt-0 flex align-items-center">
            <h4 class="title col-12 md:col-5 m-0">
              {{ $t("address") }}
            </h4>
            <div class="col-12 md:col-7">
              <Textarea v-model="formObject.address" rows="5" cols="30" />
            </div>
          </div>
        </div>
        <div class="col-12 sm:col-6 sm:pr-3 md:col-5 md:pl-5">
          <div class="bl-info mb-5">
            <h4 class="title mb-3 text-center">
              {{ $t("profile_image") }}
            </h4>
            <div class="flex align-items-center flex-wrap">
              <!--Default picture-->
              <div class="col-12 flex justify-content-center">
                <img
                  v-if="!formObject.imgUrl && !fileUploaded"
                  :src="$root.images.defaultPicture"
                  style="max-height: 130px"
                />
                <!--Client picture-->
                <ShowUserImage
                  v-if="formObject.imgUrl && !fileUploaded"
                  :url="formObject.imgUrl"
                  width="130"
                  height="130"
                />
                <!--Uploaded picture-->
                <div v-if="fileUploaded">
                  <ShowUserImage
                    :url="fileUploaded.url"
                    img-class="cursor-pointer"
                    :img-alt="fileUploaded.realFilename"
                    width="130"
                    height="130"
                  />
                </div>
              </div>
              <div class="mt-4 flex justify-content-center w-full">
                <div class="col-12 sm:col-6">
                  <FileUpload
                    :url="$root.api.fileManagerSave"
                    :choose-label="$t('add_file')"
                    class="attach-files p-button-sm"
                    mode="basic"
                    name="files[]"
                    accept="image/*"
                    auto
                    @select="onFileSelect"
                    @before-send="beforeUpload"
                    @upload="afterUpload"
                  />
                </div>
                <div v-if="fileUploaded" class="col-12 sm:col-6">
                  <Button
                    :label="$t('btn_remove')"
                    class="
                      p-button
                      p-component
                      p-button-outlined
                      p-button-danger
                      p-button-sm
                    "
                    type="button"
                    icon="pi pi-times"
                    @click="cancelPhotoUpload"
                  />
                </div>
              </div>
            </div>
            <!-- <small v-if="fileUploaded">{{ fileUploaded.realFilename }}</small>-->
          </div>
          <div class="bl-info flex align-items-center grid mb-5">
            <h4 class="title col-8 sm:col-6 md:col-9 mb-0">
              {{ $t("subscribe_messages_promo") }}
            </h4>
            <div class="flex col-4 sm:col-6 md:col-3 justify-content-end">
              <InputSwitch v-model="formObject.receivePromotion" />
            </div>
          </div>
          <div class="bl-info mb-5">
            <Button
              :label="$t('change_password')"
              class="p-button-sm mb-3"
              @click="editPassword = !editPassword"
            />
            <div v-if="editPassword">
              <div class="grid mt-0 mb-3 flex align-items-center">
                <h4 class="title col-12 mb-1">
                  {{ $t("password") }}
                </h4>
                <div class="col-12">
                  <InputText
                    v-model="password"
                    type="password"
                    autocomplete="on"
                  />
                </div>
              </div>
              <div class="grid mt-0 mb-3 flex align-items-center">
                <h4 class="title col-12 mb-1">
                  {{ $t("password_confirmation") }}
                </h4>
                <div class="col-12">
                  <InputText
                    v-model="passwordConfirmation"
                    type="password"
                    autocomplete="on"
                  />
                </div>
              </div>
              <small>{{ $t("password_rules") }}</small>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="bl-info mb-5">
            <h4 class="title col-12 mb-1 grid">
              {{ $t("phones") }}
            </h4>
            <div
              v-for="(clientPhone, key) in formObject.clientPhones"
              :key="key"
              class="grid mt-3"
            >
              <div class="col-6 mb-3 sm:col-3 sm:mb-0">
                <Dropdown
                  v-model="clientPhone.description"
                  :options="$root.options.phones"
                  :placeholder="$t('dropdown_select')"
                />
              </div>
              <div class="col-6 mb-3 sm:col-3 sm:mb-0">
                <Dropdown
                  v-model="clientPhone.code"
                  :options="countries"
                  :placeholder="$t('dropdown_select')"
                  option-label="name"
                  option-value="phoneCode"
                  filter
                  show-clear
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" key="has-country-selected">
                      +{{ clientPhone.code }}
                    </div>
                    <div v-else key="select-country">
                      {{ slotProps.placeholder }}
                    </div>
                  </template>
                  <template #option="slotProps">{{
                    slotProps.option.name
                  }}</template>
                </Dropdown>
              </div>
              <div class="col-10 sm:col-5">
                <InputText
                  v-model="clientPhone.phone"
                  placeholder="912123123"
                  type="text"
                />
              </div>
              <div class="col-2 sm:col-1">
                <Button
                  class="p-button-danger p-button-outlined p-button-sm w-full"
                  icon="pi pi-times"
                  @click="removePhoneNumber(key)"
                />
              </div>
            </div>
            <div class="grid mt-3 justify-content-end">
              <div class="col-2 sm:col-1">
                <Button
                  class="p-button-outlined p-button-sm w-full"
                  icon="pi pi-plus"
                  @click="addPhoneNumber"
                />
              </div>
            </div>
          </div>
          <div class="bl-info mb-5">
            <h4 class="title col-12 mb-1 grid">
              {{ $t("emails") }}
            </h4>
            <div
              v-for="(clientEmail, key) in formObject.clientEmails"
              :key="key"
              class="grid mt-3"
            >
              <div class="col-3 sm:col-4 md:col-3">
                <Dropdown
                  v-model="clientEmail.description"
                  :options="$root.options.emails"
                  :placeholder="$t('dropdown_select')"
                />
              </div>
              <div class="col-7 md:col-8">
                <InputText
                  v-model="clientEmail.email"
                  :placeholder="$t('email_insert')"
                  type="text"
                />
              </div>
              <div class="col-2 sm:col-1">
                <Button
                  class="p-button-danger p-button-outlined p-button-sm w-full"
                  icon="pi pi-times"
                  @click="removeEmail(key)"
                />
              </div>
            </div>
            <div class="grid mt-3 justify-content-end">
              <div class="col-2 sm:col-1">
                <Button
                  class="p-button-outlined p-button-sm w-full"
                  icon="pi pi-plus"
                  @click="addEmail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormFooter
        :show-keep-window-open="false"
        :is-loading="isLoading"
        :submit-text="$t('save')"
        @cancel="isVisible = false"
        @submit="validateInputs"
      />
    </Sidebar>
  </div>
</template>

<script>
import FormFooter from "../utils/FormFooter.vue";
import InputSwitch from "primevue/inputswitch";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { FormFooter, InputSwitch, ShowUserImage },
  props: { visible: Boolean },
  emits: ["sidebar-closed"],
  data() {
    return {
      isVisible: false,
      formObject: {},
      password: "",
      passwordConfirmation: "",
      isLoading: false,
      editPassword: false,
      maritalStatusOptions: [
        { text: "Solteiro(a)", value: "SINGLE" },
        { text: "Divorciado(a)", value: "DIVORCED" },
        { text: "Viúvo(a)", value: "WIDOWED" },
        {
          text: "Casado(a) - Comunhão de adquiridos",
          value: "MARRIED_ACQUIRED",
        },
        {
          text: "Casado(a) - Comunhão geral de bens",
          value: "MARRIED_GENERAL",
        },
        {
          text: "Casado(a) - Separação de bens",
          value: "MARRIED_SEPARATION",
        },
        {
          text: "Casado(a) - Outros regimes que os nubentes convencionem",
          value: "MARRIED_OTHER",
        },
      ],
      countries: [],
      professions: [],
      fileUploaded: null,
      filePersisted: null,
      showImageFullScreen: false,
      maxFileSize: 2000000, // Tamanho máximo de 2 MB
      canUpload: true,
    };
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      let clone = this.$options.data();
      delete clone.isVisible;
      Object.assign(this.$data, clone);
      if (!val) {
        this.$emit("sidebar-closed");
      } else {
        this.getClient();
        this.getCountries();
        this.getProfessions();
      }
    },
  },
  methods: {
    onFileSelect(event) {
      const file = event.files[0];
      this.isLoading = false;
      if (file && file.size > this.maxFileSize) {
        this.$root.toastError(
          this.$t("error"),
          this.$t("max_file_size_allowed", {
            fileSize: `${this.maxFileSize / 1000000} MB`,
          })
        );
        this.canUpload = false;
        return;
      }
      this.canUpload = true;
    },
    beforeUpload(request) {
      if (!this.canUpload) {
        return false;
      }
      this.isLoading = true;
      return request.xhr.setRequestHeader(
        "Authorization",
        this.$root.axiosGetAuth()
      );
    },
    afterUpload(request) {
      this.isLoading = false;
      this.filePersisted = null;
      this.fileUploaded = JSON.parse(request.xhr.response).data[0];
      this.fileUploaded.context = "profile";
    },
    cancelPhotoUpload() {
      this.fileUploaded = this.filePersisted;
    },
    validateInputs() {
      let isInvalidInput = false;
      if (this.editPassword && this.password !== "") {
        if (this.password !== this.passwordConfirmation) {
          this.$root.toastError("passwords_dont_match");
          isInvalidInput = true;
        }
        let passwordValid = this.password.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^.{8,}$/
        );
        if (passwordValid == null) {
          this.$root.toastError("password_rules");
          isInvalidInput = true;
        }
      }
      this.formObject.clientPhones.forEach((phone) => {
        if (
          phone.description.length == 0 ||
          phone.code.length == 0 ||
          phone.phone.length == 0
        ) {
          this.$root.toastError("all_phone_fields_are_required");
          isInvalidInput = true;
        }
      });
      this.formObject.clientEmails.forEach((email) => {
        if (
          email.description.length == 0 ||
          email.email.length == 0 ||
          !this.$root.emailRegex.test(email.email)
        ) {
          this.$root.toastError(
            this.$t("specific_email_is_invalid", {
              email: email.email,
            })
          );
          isInvalidInput = true;
        }
      });
      if (!isInvalidInput) {
        this.isLoading = true;
        if (this.fileUploaded && !this.filePersisted) {
          this.persistFile();
        } else {
          this.updateProfile();
        }
      }
    },
    persistFile() {
      if (this.formObject.imgUrl) {
        this.fileUploaded.replaceFile = this.formObject.imgUrl;
      }
      let files = {
        files: [this.fileUploaded],
      };
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, files)
        .then((response) => {
          this.filePersisted = this.fileUploaded;
          this.fileUploaded = null;
          this.formObject.imgUrl = response.data.data[0].url;
          this.updateProfile();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    updateProfile() {
      this.isLoading = true;
      let formObject = Object.assign({}, this.formObject);
      if (this.editPassword) {
        formObject.password = this.password;
        formObject.passwordConfirmation = this.passwordConfirmation;
      }
      formObject.birthDate = this.$root.setDateFromCalendar(
        formObject.birthDate
      );
      this.$root
        .apiPut(this.$root.api.profile, formObject)
        .then((response) => {
          this.isLoading = false;
          this.$root.toastSuccess(response.data.msg_code);
          setTimeout(() => {
            this.isVisible = false; //close the sidebar
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getClient() {
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.profile, { params: { dataType: "profile" } })
        .then((response) => {
          this.isLoading = false;
          this.formObject = response.data.data;
          if (!this.formObject.clientPhones.length) {
            this.addPhoneNumber();
          }
          if (!this.formObject.clientEmails.length) {
            this.addEmail();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    addPhoneNumber() {
      if (this.formObject)
        this.formObject.clientPhones.push({
          description: "",
          code: null,
          phone: "",
        });
    },
    addEmail() {
      this.formObject.clientEmails.push({ description: "", email: "" });
    },
    removePhoneNumber(key) {
      delete this.formObject.clientPhones[key];
      this.formObject.clientPhones.splice(key, 1);
    },
    removeEmail(key) {
      delete this.formObject.clientEmails[key];
      this.formObject.clientEmails.splice(key, 1);
    },
    getCountries() {
      this.$root
        .apiGet(this.$root.api.countries, { params: { dataType: "short" } })
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getProfessions() {
      this.$root
        .apiGet(this.$root.api.professions)
        .then((response) => {
          this.professions = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
