<template>
  <h1>
    {{ $t("files") }}
  </h1>
  <ProgressBar v-if="isLoading" mode="indeterminate" class="mb-4" />
  <div v-else class="grid">
    <!-- Tabela -->
    <div class="col-12">
      <div class="">
        <TreeTable
          :value="files"
          :expanded-keys="expandedKeys"
          :filters="filters"
          class="p-treetable-sm mb-5"
          sort-mode="single"
          filter-mode="lenient"
          responsive-layout="scroll"
          resizable-columns
        >
          <Column field="name" :header="$t('name')" expander sortable>
            <template #filter>
              <InputText
                v-model="filters['name']"
                type="text"
                class="p-column-filter w-full"
                placeholder="Nome"
              />
            </template>
          </Column>
          <Column
            field="createdAt"
            :header="$t('date')"
            header-class="sm-invisible text-center"
            body-class="sm-invisible"
            sortable
          >
            <template #filter>
              <div class="sm-invisible">
                <Calendar
                  v-model="filters['createdAt']"
                  :show-icon="true"
                  placeholder="31/12/1950"
                  date-format="dd/mm/yy"
                  class="w-full onlyIcon"
                  :show-button-bar="true"
                  @date-select="onDateChange"
                />
              </div>
            </template>
          </Column>
          <Column
            header-class="text-center"
            body-class="text-center"
            :header="$t('files')"
          >
            <template #body="slotProps">
              <PostFile
                v-if="slotProps.node.data.type !== 'folder'"
                :file="slotProps.node.data"
                :display-name="true"
                class="file-manager-displayed-file d-contents"
                @show-file="setShowFile(slotProps.node.data)"
              />
            </template>
          </Column>
          <Column :header="$t('actions')" class="text-center">
            <template #body="slotProps">
              <Button
                v-if="slotProps.node.data.type !== 'folder'"
                :icon="
                  slotProps.node.data.type &&
                  slotProps.node.data.type === 'folder'
                    ? 'pi pi-plus'
                    : 'pi pi-pencil'
                "
                class="p-button p-small"
                type="button"
                @click="toggle($event, slotProps.node)"
              />
            </template>
          </Column>
          <template #footer>
            <div class="flex justify-content-end mt-4">
              <Button
                class="p-button-sm mr-3"
                icon="pi pi-refresh"
                @click="getFiles"
              />
              <Button
                :label="$t('open_folders')"
                class="p-button-sm mr-3"
                type="button"
                icon="pi pi-plus"
                @click="expandAll"
              />
              <Button
                :label="$t('close_folders')"
                type="button"
                class="p-button-sm"
                icon="pi pi-minus"
                @click="collapseAll"
              />
            </div>
          </template>
        </TreeTable>
      </div>
    </div>
  </div>
  <TieredMenu ref="opMenu" :model="items" popup />
  <DialogFiles
    :files="selectedNode ? [selectedNode.data] : []"
    :display-files="showFile"
    @closed="showFile = false"
  />
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import TreeTable from "primevue/treetable";
import PostFile from "../posts/PostFile.vue";
import DialogFiles from "./DialogFiles.vue";

export default {
  components: { TreeTable, PostFile, TieredMenu, DialogFiles },
  data() {
    return {
      files: [],
      isLoading: false,
      expandedKeys: {},
      filters: {
        name: "", // Para o filtro de nome
        createdAt: null, // Para o filtro de data, use null inicialmente
      },
      selectedNode: {},
      showAddFolder: false,
      showAddFiles: false,
      showFile: false,
      folderPath: "",
      items: [
        {
          label: this.$t("view_file"),
          icon: "pi pi-fw pi-search",
          command: () => {
            this.showFile = true;
          },
          visible: () => this.selectedNode.data.type !== "folder",
        },
        {
          label: this.$t("download"),
          icon: "pi pi-fw pi-external-link",
          command: () => {
            this.downloadFile();
          },
          visible: () => this.selectedNode.data.type !== "folder",
        },
      ],
    };
  },
  created() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.resetVariables();
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.processFileUrlsClient)
        .then((response) => {
          this.$root
            .apiGet(this.$root.getApiClientFiles(this.$root.user.id), {
              params: { processFileUrls: response.data.processFileUrls },
            })
            .then((response) => {
              this.isLoading = false;
              this.files = response.data.data;
            })
            .catch((error) => {
              this.isLoading = false;
              this.$root.toastError(error.response.data.msg_code);
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    expandAll() {
      for (let node of this.files) {
        this.expandNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    toggle(event, node) {
      this.$refs.opMenu.toggle(event);
      this.selectedNode = node;
      if (this.selectedNode && this.selectedNode.data.path) {
        this.folderPath = this.selectedNode.data.path;
      }
    },
    downloadFile() {
      let url = this.selectedNode.data.url;
      let filename = this.selectedNode.data.name;
      this.$root
        .apiGetFile(this.$root.getUrlFromFileToDownload(url))
        .then((response) => {
          this.$root.downloadFile(
            response.data,
            response.headers,
            filename,
            url
          );
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetVariables() {
      this.selectedNode = {};
      this.showAddFolder = false;
      this.showAddFiles = false;
    },
    setShowFile(node) {
      this.selectedNode.data = node;
      this.showFile = true;
    },
    onDateChange(value) {
      // Converte a data para o formato desejado "dd/MM/yyyy"
      this.filters["createdAt"] = this.formatDate(value);
    },
    formatDate(date) {
      if (!(date instanceof Date)) return "";

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
};
</script>
