<template>
  <LoginBaseTemplate :title="$t('reset_password')">
    <div v-if="isTokenValid">
      <div v-if="showForm">
        <div class="">
          <label for="password" class="w-full">Insira senha nova</label>
          <Password
            id="password"
            v-model="password"
            :feedback="false"
            :toggle-mask="true"
            class="mb-1 w-full"
            @blur="validatePassword"
          />
          <small v-if="passwordError" class="p-error w-full block mb-2">
            {{ passwordError }}
          </small>
        </div>
        <div class="">
          <label for="passwordConfirmation" class="w-full"
            >Confirme a Senha</label
          >
          <Password
            id="passwordConfirmation"
            v-model="passwordConfirmation"
            :feedback="false"
            :toggle-mask="true"
            class="mb-1 w-full"
            @blur="validatepasswordConfirmation"
          />
          <small
            v-if="validatepasswordConfirmationError"
            class="p-error w-full mb-2 block"
          >
            {{ validatepasswordConfirmationError }}
          </small>
        </div>

        <small class="w-full mb-2 block">{{ $t("password_rules") }}</small>

        <button
          type="button"
          class="p-button p-component justify-content-center"
          @click="validatePassword2()"
        >
          {{ $t("recover_account") }}
        </button>
        <ProgressBar
          v-if="isLoading"
          class="text-center"
          mode="indeterminate"
        />
      </div>
      <p v-if="messageError">
        <InlineMessage severity="error">{{ $t(messageError) }}</InlineMessage>
      </p>
      <div v-if="messageSuccess">
        <p>
          <InlineMessage severity="success">
            {{ $t(messageSuccess) }}
          </InlineMessage>
        </p>
        <button
          type="button"
          class="p-button p-small p-component justify-content-center"
          @click="goToLogin"
        >
          {{ $t("login") }}
        </button>
      </div>
      <p v-else class="message text-sm">
        <a
          class="p-button p-small p-component justify-content-center"
          @click="goToLogin"
          >{{ $t("login") }}</a
        >
      </p>
    </div>

    <div v-else-if="messageError">
      <InlineMessage severity="error" class="mb-3">{{
        $t(messageError)
      }}</InlineMessage>
      <button
        type="button"
        class="p-button p-small p-component justify-content-center"
        @click="goToLogin"
      >
        {{ $t("login") }}
      </button>
    </div>
  </LoginBaseTemplate>
</template>

<script>
import Password from "primevue/password";
import LoginBaseTemplate from "./LoginBaseTemplate.vue";
import InlineMessage from "primevue/inlinemessage";

export default {
  components: { LoginBaseTemplate, InlineMessage, Password },
  extends: LoginBaseTemplate,
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      token: null,
      isTokenValid: false,
      isLoading: false,
      showForm: true,
      messageError: null,
      messageSuccess: null,
      passwordError: "",
      validatepasswordConfirmationError: "",
    };
  },
  watch: {
    // Resetar mensagens de erro quando o usuário está digitando novamente
    password() {
      this.passwordError = "";
    },
    validatepasswordConfirmation() {
      this.validatepasswordConfirmationError = "";
    },
  },
  created() {
    this.tokenConfirmation();
  },
  methods: {
    getToken() {
      return new URLSearchParams(window.location.search).get("token");
    },
    tokenConfirmation() {
      this.messageError = this.messageSuccess = null;
      this.$root
        .apiPost(this.$root.api.tokenConfirmation, { token: this.getToken() })
        .then(() => {
          this.isTokenValid = true;
        })
        .catch((error) => {
          this.messageError = error.response.data.msg_code;
        });
    },
    validatePassword() {
      const password = this.password;
      this.passwordError = "";

      // Verificar se a senha contém pelo menos 8 caracteres
      if (password.length < 8) {
        this.passwordError = "A senha deve ter pelo menos 8 caracteres.";
        return;
      }

      // Verificar se a senha contém pelo menos um dígito
      if (!/\d/.test(password)) {
        this.passwordError = "A senha deve conter pelo menos um dígito.";
        return;
      }

      // Verificar se a senha contém uma letra minúscula e uma maiúscula
      if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
        this.passwordError =
          "A senha deve conter uma letra minúscula e uma maiúscula.";
        return;
      }
    },
    validatepasswordConfirmation() {
      if (this.passwordConfirmation !== this.password) {
        this.confirmPasswordError = "As senhas não correspondem.";
      } else {
        this.confirmPasswordError = "";
      }
    },
    validatePassword2() {
      this.messageError = null;
      if (this.password !== this.passwordConfirmation) {
        this.messageError = "passwords_dont_match";
      }
      if (
        this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^.{8,}$/) == null
      ) {
        this.messageError = "password_rules";
      }
      if (!this.messageError) {
        this.setPassword();
      }
    },
    setPassword() {
      let credentials = {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.getToken(),
      };
      this.isLoading = true;
      this.messageError = this.messageSuccess = null;
      this.$root
        .apiPutUnauthenticated(this.$root.api.resetPassword, credentials)
        .then((response) => {
          this.isLoading = this.showForm = false;
          this.messageSuccess = response.data.msg_code;
        })
        .catch((error) => {
          this.isLoading = false;
          this.messageError = error.response.data.msg_code;
        });
    },
    goToLogin() {
      this.$router.push({ name: "login" }).catch(() => {});
    },
  },
};
</script>
