import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });
const store = createStore({
  state() {
    return {
      token: "",
      allowedModules: [],
      hasBalcaoMais: false,
      pendingClientRgpdToken: null,
      locale: "",
    };
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setAllowedModules: (state, allowedModules) => {
      state.allowedModules = allowedModules;
    },
    setHasBalcaoMais: (state, hasBalcaoMais) => {
      state.hasBalcaoMais = hasBalcaoMais;
    },
    setPendingClientRgpdToken: (state, pendingClientRgpdToken) => {
      state.pendingClientRgpdToken = pendingClientRgpdToken;
    },
    setLocale: (state, locale) => {
      state.locale = locale;
    },
    clearToken: (state) => {
      state.token = "";
      state.allowedModules = [];
      state.hasBalcaoMais = false;
      state.pendingClientRgpdToken = null;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default store;
