<template>
  <div class="card-sidebar">
    <h1
      v-if="process.state && process.state !== 'ongoing'"
      class="title w-full pb-2"
    >
      {{ translateProcessStateToPt(process.state) }}
    </h1>
    <!-- Phase -->
    <div
      v-if="process.phases && process.phases.length"
      class="mb-4 list-phases"
    >
      <h2 class="title w-full pb-2">{{ $t("process_phase") }}</h2>
      <div
        v-for="(phase, index) in process.phases"
        :key="phase.id"
        class="mb-2"
        :class="{ 'font-bold': phase.id == process.phase.id }"
      >
        <span>{{ index + 1 }}</span> - {{ phase.name }}
      </div>
    </div>

    <!-- Files -->
    <div v-if="process.files && process.files.length" class="mb-4 list-files">
      <h2 class="title w-full pb-2">{{ $t("files") }}</h2>

      <div
        v-for="(files, description) in process.filesOptimized"
        :key="description"
        class="mb-2"
      >
        <div class="text-sm mb-2 mt-4">{{ description }}</div>
        <div v-for="file in files" :key="file.key">
          {{ file.userName }}
          <Button
            class="p-button-text w-12 mb-1"
            @click="setDisplayFiles(file.key, null)"
          >
            <img
              loading="lazy"
              :src="`${$root.images.filetypes}/${file.url
                .split('.')
                .pop()}.png`"
              width="30"
              height="30"
              alt="Tipo de ficheiro"
              class="mr-3"
              @error="$root.setDefaultFiletype"
            />
            {{ file.name }}
          </Button>
        </div>
      </div>

      <div
        v-for="(files, name) in process.opportunityFiles"
        :key="name"
        class="mb-2"
      >
        <div class="text-sm mb-2 mt-4">{{ name }}</div>
        <div v-for="(file, key) in files" :key="key">
          {{ file.userName }}
          <Button
            class="p-button-text text-left w-12"
            aria-haspopup="true"
            @click="setDisplayFiles(null, file.url)"
            @contextmenu="showContextMenu($event, file)"
          >
            <img
              loading="lazy"
              :src="`${$root.images.filetypes}/${file.url
                .split('.')
                .pop()}.png`"
              width="30"
              height="30"
              alt="Tipo de ficheiro"
              @error="$root.setDefaultFiletype"
            />
            {{ file.name }}
          </Button>
        </div>
      </div>
    </div>
    <!-- gestores -->
    <div v-if="process.users" class="mb-2">
      <h3 class="title w-full pb-2">{{ $t("process_managers") }}</h3>
      <div class="flex align-items-center flex-wrap">
        <ShowUserImage
          v-for="user in process.users"
          :key="user.id"
          v-tooltip.bottom="user.name"
          :url="user.imgUrl"
          class="mb-2"
          :img-alt="`Foto de ${user.name}`"
          max-width="28"
          height="28"
        />
      </div>
    </div>
  </div>
  <DialogFiles
    :files="process.files"
    :display-files="fileSelectedIndex != null"
    :file-selected-index="fileSelectedIndex"
    @closed="setDisplayFiles(null, null)"
  />
</template>

<script>
import useDropdownOptions from "../../composables/useDropdownOptions.js";
import DialogFiles from "../files/DialogFiles.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  name: "ProcessInfo",
  components: { DialogFiles, ShowUserImage },
  props: { process: { type: Object, default: null } },
  setup() {
    const { translateProcessStateToPt } = useDropdownOptions();
    return { translateProcessStateToPt };
  },
  data() {
    return { fileSelectedIndex: null };
  },
  methods: {
    setDisplayFiles(index, url) {
      if (index == null) {
        index = this.process.files.findIndex(
          (file) => file && file.url === url
        );
        if (index == -1) {
          index = null;
        }
      }
      this.fileSelectedIndex = index;
    },
  },
};
</script>
