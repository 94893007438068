<template>
  <div v-if="bussola" class="pb-0 flex flex-wrap card-sidebar">
    <h2 class="title w-full pb-2">"A Bússola"</h2>
    <div class="w-full flex card-content">
      <a v-if="bussola.id" href="#" @click="openInNewTab">
        <img
          :src="thumbnail"
          class="pr-5 pb-0"
          style="height: 200px; display: block"
        />
      </a>
      <div class="flex flex-column justify-content-between w-full">
        <div class="text-700">{{ $t("edition_number") }}{{ number }}</div>
        <div
          class="see_more cursor-pointer py-1 px-4 text-xs ml-auto text-center"
          @click="openInNewTab"
        >
          {{ $t("see_more") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: { bussola: { type: Object, default: null } },
  setup() {
    const thumbnail = ref("");
    return { thumbnail };
  },
  computed: {
    number() {
      if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
        const numberMatch = this.bussola.title.rendered.match(/#(\d+)/);
        return numberMatch ? numberMatch[1] : null;
      }
      return null;
    },
    publishDate() {
      if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
        const dateMatch = this.bussola.title.rendered.match(/(\w+ \d+)$/);
        return dateMatch ? dateMatch[1] : null;
      }
      return null;
    },
  },
  mounted() {
    if (
      this.bussola &&
      this.bussola.x_metadata &&
      this.bussola.x_metadata._elementor_data
    ) {
      const jsonObject = JSON.parse(this.bussola.x_metadata._elementor_data);
      if (!jsonObject) {
        return null;
      }
      const index = jsonObject.length > 1 ? 1 : 0;
      if (
        jsonObject &&
        jsonObject[index] &&
        jsonObject[index].elements &&
        jsonObject[index].elements[0] &&
        jsonObject[index].elements[0].elements &&
        jsonObject[index].elements[0].elements[0] &&
        jsonObject[index].elements[0].elements[0].settings
      ) {
        let shortcode =
          jsonObject[index].elements[0].elements[0].settings.shortcode;
        if (!shortcode) {
          shortcode = jsonObject[index].elements[0].elements[0].settings.editor;
        }
        if (shortcode) {
          const id = shortcode.match(/id=\\?"(\d+)\\?"/)[1];
          this.thumbnail = this.$root.getApiExternalBussolaThumbnail(id);
        }
      }
    }
  },
  methods: {
    openInNewTab() {
      window.open(this.bussola.link, "_blank");
    },
  },
};
</script>
