<template>
  <div>
    <div class="mb-6 post-card block mt-0">
      <CreatePost @post-created="resetPosts()" />
    </div>
    <div v-for="post in posts" :key="post.id">
      <PostTemplate v-if="post" :post="post" />
    </div>
    <div v-if="hasMorePosts" class="flex justify-content-end">
      <Button
        :disabled="isLoading"
        :post-comment-cardlabel="$t('load_more')"
        :label="$t('load_more')"
        class="post-button-load-more"
        @click="getPosts()"
      />
      <ProgressBar v-if="isLoading" class="text-center" mode="indeterminate" />
    </div>
  </div>
</template>

<script>
import PostTemplate from "./PostTemplate.vue";
import CreatePost from "./CreatePost.vue";

export default {
  components: { PostTemplate, CreatePost },
  data() {
    return {
      posts: [],
      isLoading: false,
      offset: 0,
      hasMorePosts: true,
    };
  },
  created() {
    this.getPosts();
  },
  methods: {
    resetPosts() {
      this.offset = 0;
      this.posts = [];
      this.getPosts();
    },
    getPosts() {
      this.isLoading = true;
      let params = { dataType: "list", offset: this.offset };
      this.$root
        .apiGet(this.$root.api.posts, { params })
        .then((response) => {
          this.isLoading = false;
          if (response.data.data.length !== 0) {
            this.posts = this.posts.concat(response.data.data);
            this.offset += 10;
          } else {
            this.hasMorePosts = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
