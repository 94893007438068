<template>
  <div class="p-3">
    <div class="title">
      <h4>{{ $t("new_post_title") }}</h4>
    </div>
    <Textarea
      v-model="message"
      :placeholder="$t('new_post_placeholder')"
      class="mb-4 col-12 p-4"
      rows="5"
      auto-resize
    />
    <FileUploader
      :type="'post'"
      :uploader-id="'uploadFilesForPost'"
      :upload-url="$root.api.fileManagerSave"
      :textbtn="$t('publish')"
      class-img-upload="no_rounded"
      @files-uploaded="onFilesUploaded"
      @progress="setUploadProgress"
      @error="isLoading = false"
    />
  </div>
</template>

<script>
import FileUploader from "../utils/FileUploader.vue";

export default {
  components: { FileUploader },
  props: { processId: { type: [String, Number], default: null } },
  emits: ["post-created"],
  data() {
    return {
      message: "",
      postId: null,
      isLoading: false,
      filesUploaded: [],
      filesPersisted: [],
      uploadProgress: 0,
    };
  },
  methods: {
    onFilesUploaded(files) {
      this.filesUploaded = files;
    },
    setUploadProgress(progress) {
      this.uploadProgress = progress;
    },
    validateInputs() {
      if (!this.message || this.message.length == 0) {
        this.$root.toastError("message_is_required");
      } else {
        this.submit();
      }
    },
    submit() {
      this.isLoading = true;
      if (this.filesPersisted.length > 0) {
        this.createPostFiles();
      } else {
        this.createPost();
      }
    },
    createPost() {
      let parameters = {
        message: this.message,
        attachments: this.filesUploaded,
        processId: this.processId,
      };
      this.postId = null;
      this.$root
        .apiPost(this.$root.api.posts, parameters)
        .then((response) => {
          if (this.filesUploaded.length > 0) {
            this.postId = response.data.data;
            this.persistFile(response.data.data);
          } else {
            this.resetData();
            this.$root.toastSuccess(response.data.msg_code);
            this.$emit("post-created");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    persistFile() {
      this.filesUploaded.forEach((file) => {
        file.context = this.processId
          ? `process/${this.processId}/post/${this.postId}`
          : `post/${this.postId}`;
      });
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, {
          files: this.filesUploaded,
        })
        .then((response) => {
          this.filesUploaded = [];
          response.data.data.forEach((file) => {
            if (!file.url.startsWith("tmp/")) {
              this.filesPersisted.push(file);
            }
          });
          this.createPostFiles();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    createPostFiles() {
      let parameters = {
        attachments: this.filesPersisted,
        postId: this.postId,
        notify: true,
      };
      this.$root
        .apiPost(this.$root.api.postFiles, parameters)
        .then((response) => {
          this.resetData();
          this.$root.toastSuccess(response.data.msg_code);
          this.$emit("post-created");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetData() {
      this.isLoading = false;
      this.message = "";
      this.filesUploaded = [];
      this.postId = null;
      this.filesPersisted = [];
    },
  },
};
</script>
