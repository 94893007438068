export function useDropdownOptions() {
  const processStates = [
    { label: "Em curso", value: "ongoing" },
    {
      label: "Concluído",
      value: "concluded",
      description: "Processo chegou ao fim",
    },
    {
      label: "Desistência",
      value: "waiver",
      description: "Processo fechado definitivamente",
    },
    {
      label: "Arquivado",
      value: "archived",
      description: "Processo parado mas pode ser reaberto",
    },
  ];
  const translateProcessStateToPt = (state) => {
    if (!state) {
      return null;
    }
    let stateFound = processStates.find((s) => s.value === state);
    return stateFound ? stateFound.label : null;
  };

  return {
    processStates,
    translateProcessStateToPt,
  };
}

export default useDropdownOptions;
