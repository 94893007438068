/* eslint-disable no-undef */
import dayjs from "dayjs";

const dateFormat = {
  methods: {
    setDateLocale(locale) {
      if (locale == "CH") {
        //Switzerland
        require("dayjs/locale/fr-ch");
        dayjs.locale("fr-ch");
      } else {
        require("dayjs/locale/pt");
        dayjs.locale("pt");
      }
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      let d = !date.date ? date : date.date;
      return dayjs(d).format("DD-MM-YYYY");
    },
    formatDateTime(date) {
      if (!date) {
        return null;
      }
      let d = !date.date ? date : date.date;
      date = dayjs(d);
      return date.format("DD-MM-YYYY") + " às " + date.format("HH:mm");
    },
    formatTime(date) {
      if (!date) {
        return null;
      }
      let d = !date.date ? date : date.date;
      return dayjs(d).format("HH:mm");
    },
    differenceFromNow(date) {
      var start = new Date(date).getTime();
      var end = new Date().getTime();
      var time = ((end - start) / 1000 / 60).toFixed(0);
      if (time < 1) {
        return "agora";
      } else if (time == 1) {
        return time + " minuto";
      } else if (time > 1 && time <= 60) {
        return time + " minutos";
      } else if (time >= 60 && time <= 60 * 24) {
        return (time / 60).toFixed(0) + " horas";
      } else if (time >= 60 * 24) {
        if ((time / 60) * 24 > 1 && (time / 60) * 24 < 2) {
          return "ontem";
        } else {
          return dayjs(start).format("DD/MM/YYYY HH:mm:ss");
        }
      }
    },
    setDateFromCalendar(date) {
      if (!date) {
        return null;
      }
      if (typeof date === "string") {
        if (date.includes("/")) {
          let d = date.split("/");
          date = new Date(d[2], d[1] - 1, d[0]);
        } else {
          date = new Date(date);
        }
      }
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};

export default dateFormat;
