<template>
  <Dialog
    v-if="files && files[index]"
    v-model:visible="isVisible"
    :maximizable="$root.fileIsPdf(files[index])"
    :draggable="false"
    modal
    close-on-escape
    @maximize="fullScreen = true"
    @unmaximize="fullScreen = false"
    @hide="closed"
    @keyup.left="moveLeft"
    @keyup.right="moveRight"
    @keyup.up="moveLeft"
    @keyup.down="moveRight"
  >
    <template #header>
      <Button
        v-if="files[index].url && !files[index].url.startsWith('http')"
        :label="files[index].name"
        class="p-button-sm"
        type="button"
        icon="pi pi-download"
        @click="downloadFile(files[index].url, files[index].name)"
      />
      <div v-else>{{ files[index].name }}</div>
    </template>
    <div class="flex center" :class="{ 'h-full': fullScreen }">
      <Button
        v-if="files.length > 1"
        class="p-button-rounded p-button-outlined mr-1"
        icon="pi pi-angle-left"
        @click="moveLeft"
      />
      <ShowFile
        :file="files[index]"
        :show-download="false"
        :full-screen="fullScreen"
        :autoplay="autoplay"
      />
      <Button
        v-if="files.length > 1"
        class="p-button-rounded p-button-outlined ml-1"
        icon="pi pi-angle-right"
        @click="moveRight"
      />
    </div>
  </Dialog>
</template>

<script>
import ShowFile from "./ShowFile.vue";

export default {
  components: { ShowFile },
  props: {
    files: { type: Array, default: null },
    displayFiles: Boolean,
    fileSelectedIndex: { type: Number, default: null },
    autoplay: { type: Boolean, default: false },
  },
  emits: ["closed"],
  data() {
    return {
      isVisible: false,
      fullScreen: false,
      index: 0,
    };
  },
  watch: {
    fileSelectedIndex(val) {
      this.index = val;
    },
    isVisible(val) {
      this.index = this.fileSelectedIndex ? this.fileSelectedIndex : 0;
      if (!val) {
        this.$emit("closed");
      }
    },
    displayFiles(val) {
      this.isVisible = val;
    },
  },
  methods: {
    downloadFile(url, filename) {
      this.isLoading = true;
      this.$root
        .apiGetFile(this.$root.getUrlFromFileToDownload(url))
        .then((response) => {
          this.isLoading = false;
          this.$root.downloadFile(
            response.data,
            response.headers,
            filename,
            url
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.$root.toastError("file_not_found_or_stored");
        });
    },
    moveLeft() {
      this.index = this.index == 0 ? this.files.length - 1 : this.index - 1;
    },
    moveRight() {
      this.index = this.index == this.files.length - 1 ? 0 : this.index + 1;
    },
    closed() {
      this.$emit("closed");
      this.isVisible = false;
      this.fullScreen = false;
    },
  },
};
</script>
