<template>
  <div class="login-page">
    <Dropdown
      id="languageDropdown"
      v-model="languageSelected"
      :options="languages"
    />
    <div class="login-form">
      <img :src="$root.images.beLogo" />
      <h1 class="text-3xl bold text-center py-1 mt-3">{{ title }}</h1>
      <form @submit.prevent>
        <slot>
          <!--for components that extends this one-->
        </slot>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: { title: { type: String, default: null } },
  data() {
    return {
      languageSelected: this.$i18n.locale,
      languages: ["PT", "EN"],
    };
  },
  watch: {
    languageSelected() {
      this.$i18n.locale = this.languageSelected;
      this.$root.setDateLocale(this.languageSelected);
      this.$store.commit("setLocale", this.languageSelected);
    },
  },
  mounted() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add("login__background");
  },
  unmounted() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("login__background");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/login.scss";
</style>
