<template>
  <div class="grid">
    <div class="col-12 md:col-8 md:pr-5">
      <div class="mb-3 post-card no-print">
        <CreatePost
          :process-id="processId"
          :can-share-with-client="process.client && process.client.hasAuth"
          :is-mega-process="process.isMegaProcess"
          @post-created="resetPosts"
        />
      </div>
      <PostTemplate
        v-for="post in posts"
        :key="post.id"
        :post="post"
        show-real-user
        from-process
      />
      <div
        v-if="hasMorePosts"
        class="flex justify-content-end mt-3 align-itens-center"
      >
        <Button
          :disabled="isLoading"
          class="post-button-load-more p-button-sm"
          :label="$t('load_more')"
          @click="getPosts"
        />
        <ProgressBar v-if="isLoading" mode="indeterminate" />
      </div>
    </div>
    <div class="col-12 md:col-4 md:pl-5">
      <ProcessInfo :process="process" />
    </div>
  </div>
</template>

<script>
import CreatePost from "../posts/CreatePost.vue";
import PostTemplate from "../posts/PostTemplate.vue";
import ProcessInfo from "./ProcessInfo.vue";

const limit = 50;
export default {
  components: { PostTemplate, ProcessInfo, CreatePost },
  props: {
    processId: { type: [String, Number], default: null },
    process: { type: Object, default: null },
  },
  emits: ["changed"],
  data() {
    return {
      posts: [],
      isLoading: false,
      offset: 0,
      hasMorePosts: true,
    };
  },
  watch: {
    processId() {
      this.resetPosts();
    },
  },
  created() {
    this.getPosts();
  },
  methods: {
    resetPosts() {
      this.offset = 0;
      this.posts = [];
      this.getPosts();
      this.$emit("changed");
    },
    getPosts() {
      this.isLoading = true;
      let params = {
        offset: this.offset,
        processId: this.processId,
        limit: limit,
        dataType: "process",
      };
      this.$root
        .apiGet(this.$root.api.posts, { params })
        .then((response) => {
          this.isLoading = false;
          if (response.data.data.length !== 0) {
            this.posts.push(...response.data.data);
            this.offset += limit;
          } else {
            this.hasMorePosts = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
